import styled from 'styled-components';
import media from '../../style/media';

export const Button = styled.button`
  cursor: pointer;
  border-radius: 10px;
  border: transparent;
  ${({ disabled }) =>
    disabled &&
    `
    pointer-event: none;
    cursor: not-allowed;
    opacity: 0.9;
  `}
  :hover {
    opacity: 0.9;
  }
  height: 40px;
  ${media.mobile`
    height: 60px;
  `};
`;
