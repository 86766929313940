import styled from 'styled-components';
import { useCallback, useEffect, useState } from 'react';
import media from '../../shared/style/media';
import { SubmitButton } from './SubmitButton';
import { UserResponse } from './UserResponse';
import { QuestionType, SurveyType } from '../hooks/useCsatSurvey';
import { isAllQuestionAnswered } from '../utils';

export type UserAnswers = Record<string, string>;

export const Form = ({
  survey,
  handleSubmit,
}: {
  survey: SurveyType;
  handleSubmit: (userAnswers: UserAnswers, surveyName: string) => void;
}) => {
  const [userAnswers, setUserAnswers] = useState<UserAnswers>({});

  useEffect(() => {
    const answers: UserAnswers = {};
    survey.questions?.forEach((question) => {
      if (!question.options) {
        answers[question.id] = '';
      }
    });

    setUserAnswers(answers);
  }, [survey.questions]);

  const handleUserAnswer = useCallback((questionId: string, answer: string) => {
    setUserAnswers((prevAnswers) => ({ ...prevAnswers, [questionId]: answer }));
  }, []);

  return (
    <>
      <Title>Your feedback matters</Title>
      <Description>
        We&rsquo;d like to ask for your feedback on your experience with us.
        <br />
        Your feedback is important to us because it helps us improve our products and services.
      </Description>
      {survey?.questions?.map((question) => (
        <UserResponse handleUserAnswer={handleUserAnswer} key={question.id} question={question} />
      ))}
      <SubmitButtonWrapper>
        <SubmitButton
          text="SUBMIT FEEDBACK"
          isDisabled={!isAllQuestionAnswered(survey.questions, userAnswers)}
          onClick={() => {
            handleSubmit(userAnswers, survey.surveyName);
          }}
        >
          Submit
        </SubmitButton>
      </SubmitButtonWrapper>
    </>
  );
};

const Title = styled.div`
  color: inherit;
  font-family: MarkPro-Bold;
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 16px;
  margin-top: 50px;
  letter-spacing: -1px;
  ${media.mobileMedium`
    font-size: 28px;
    margin: 0 0 16px;
`};
`;

const Description = styled.div`
  font-size: 14px;
  text-align: center;
  margin-bottom: 32px;
`;

const SubmitButtonWrapper = styled.div`
  font-family: MarkPro-Bold;
  margin: 16px 0 50px;
`;
