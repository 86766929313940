/* eslint-disable react/require-default-props */
import { FC, useMemo } from 'react';
import styled from 'styled-components';
import { CheckButton, FlexCenter, flexColumn, paragraph3Medium } from '../../../shared/components';
import media from '../../../shared/style/media';
import { CheckButtonText } from './CheckButtonText';

interface NpsOptionsProps {
  selectedOption: string | undefined;
  disabled?: boolean;
  onSelectOption: (value: string) => void;
}

// 1-10 array
const answersOptions: string[] = Array.from({ length: 10 }, (_, i) => `${i + 1}`);

export const NpsOptions: FC<NpsOptionsProps> = ({ selectedOption, disabled, onSelectOption }) => {
  const options = useMemo(
    () =>
      answersOptions.map((answerOption) => (
        <CheckButton
          key={answerOption}
          onClick={() => onSelectOption(answerOption)}
          selected={selectedOption === answerOption}
          disabled={disabled}
        >
          <CheckButtonText>{answerOption}</CheckButtonText>
        </CheckButton>
      )),
    [selectedOption, disabled, onSelectOption]
  );

  return (
    <Wrapper>
      <ScoreExplain>Not likely</ScoreExplain>
      <OptionsWrapper>{options}</OptionsWrapper>
      <ScoreExplain>Very likely</ScoreExplain>
    </Wrapper>
  );
};

const Wrapper = styled(FlexCenter)`
  width: 100%;
  position: relative;

  ${media.mobile`
    padding-bottom: 40px;
    ${flexColumn};
    width: 280px;
`};
`;

const OptionsWrapper = styled(FlexCenter)`
  justify-content: space-around;
  width: 100%;
  ${media.mobile`
    flex-wrap: wrap; 
    width: 100%;
    > button {
      margin-bottom: 5px;
    }
  `};
`;

const ScoreExplain = styled.h3`
  width: 190px;
  text-align: center;
  margin: 0 10px;
  ${media.mobile`
    ${paragraph3Medium};
    order: 2;
    position: absolute;
    bottom: -10px;
    width: fit-content;
    &:first-of-type {
      left: -4px;
      &:before {
        content: '1- '
      }
    }
    &:nth-of-type(2) {
      right: -4px;
      &:before {
        content: '10- '
      }
    }
  `};
`;
