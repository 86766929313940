import styled, { keyframes } from 'styled-components';

const spin = keyframes`
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
`;

export const Loader = styled.div`
  border: 6px solid ${({ theme }) => theme.loader.outerColor};
  border-radius: 50%;
  border-top: 6px solid ${({ theme }) => theme.loader.innerColor};
  width: 40px;
  height: 40px;
  animation: ${spin} 2s linear infinite;
`;
