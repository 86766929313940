import styled from 'styled-components';
import { generateButtonCommonCss } from '../../style/commonCssGenerators';
import media from '../../style/media';
import { Button } from './Button';

export const SubmitButton = styled(Button)`
  width: 100%;
  border-radius: 20px;
  ${generateButtonCommonCss('secondary')};
  ${media.mobile`
    ${generateButtonCommonCss()};
    border-radius: 0;
  `};
`;
