import styled from 'styled-components';
import { useCallback, useContext } from 'react';
import { vennAnalytics } from '@venncity/monitor-ui';
import { Card, RatingMeter, Input } from '../../shared/components';
import { QuestionType } from '../hooks/useCsatSurvey';
import media from '../../shared/style/media';
import { MetadataContext, PortfolioContext } from './Csat';
import { theme } from '../../shared/style';

export const UserResponse = ({
  question,
  handleUserAnswer,
}: {
  question: QuestionType;
  handleUserAnswer: (questionId: string, answer: string) => void;
}) => {
  const portfolio = useContext(PortfolioContext);
  const metadata = useContext(MetadataContext);

  // extract this function since it rating questions also send bi-events while open questions doesn't
  const rating = useCallback(
    (rate) => {
      handleUserAnswer(question.id, (rate + 1).toString());

      vennAnalytics.track(
        {
          domain: 'CsatSurveyWebPage',
          object: 'CsatSurvey',
          action: 'Submitted',
        },
        {
          email: metadata.responderEmail,
          fullName: `${metadata.firstName} ${metadata.lastName}`,
          hoodName: metadata.hoodName,
          result: rate + 1,
        }
      );
    },
    [
      handleUserAnswer,
      metadata.firstName,
      metadata.hoodName,
      metadata.lastName,
      metadata.responderEmail,
      question.id,
    ]
  );

  if (question.options) {
    return (
      <CardWrapper>
        <Card>
          <Question>{question.text}</Question>
          <RatingMeter
            maxRating={5}
            fill={selectFill(portfolio)}
            stroke={selectStroke(portfolio)}
            onChange={rating}
          />
        </Card>
      </CardWrapper>
    );
  }

  return (
    <CardWrapper>
      <Card>
        <Question>{question.text}</Question>
        <Input
          onChange={(value) => handleUserAnswer(question.id, value)}
          maxSize={500}
          placeholder="Share your thoughts with us"
        />
      </Card>
    </CardWrapper>
  );
};

const Question = styled.div`
  font-size: 16px;
  font-family: MarkPro-Bold;
  font-weight: 700;
  ${media.mobileMedium`
    font-size: 15px;
  `};
`;

const CardWrapper = styled.div`
  margin-bottom: 16px;
  display: flex;
`;

const selectFill = (portfolio: string) => {
  switch (portfolio.toLowerCase()) {
    case 'mac':
      return theme.colors.mac;
    default:
      return theme.colors.primary;
  }
};

const selectStroke = (portfolio: string) => {
  switch (portfolio.toLowerCase()) {
    case 'mac':
      return { regular: theme.colors.mac, dark: theme.colors.macDark };
    default:
      return { regular: theme.colors.primary, dark: theme.colors.primaryDark };
  }
};
