import { css } from 'styled-components';

export const generateButtonCommonCss = (type: string = 'primary', state: string = 'normal') => css`
  background: ${({ theme }: { theme: any }) => theme.button[type][state].background};
  color: ${({ theme }: { theme: any }) => theme.button[type][state].color};

  > * {
    background: ${({ theme }: { theme: any }) => theme.button[type][state].background};
    color: ${({ theme }: { theme: any }) => theme.button[type][state].color};
  }
`;
