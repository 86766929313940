import { createGlobalStyle } from 'styled-components';
import { theme } from './theme';

export const GlobalStyle = createGlobalStyle`
body {
  margin: 0;
  background-color: ${theme.colors.stone};
  height: 100;
}
* {
    box-sizing: border-box;
    font-family: 'Mark Pro';
    user-select: none;    
}
h1, h2, h3, h4, h5, h6, p {
  margin: 0;
  color: ${({ theme }: any) => theme.text.color};
};
`;
