/* eslint-disable react/no-array-index-key */
/* eslint-disable react/require-default-props */
import React, { useState } from 'react';
import styled from 'styled-components';
import media from '../../style/media';

export const Card: React.FC = ({ children }) => {
  const childrenWithMargin = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      const childStyle = child.props.style || {};
      const mergedStyle = { ...childStyle, marginBottom: '32px' };

      return React.cloneElement(child, {
        style: mergedStyle,
      });
    }
    return child;
  });

  return <CardItem>{childrenWithMargin}</CardItem>;
};

const CardItem = styled.div`
  width: 700px;
  min-height: 230px;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  background-color: ${({ theme }) => theme.colors.white};
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 32px 180px;
  ${media.mobileMedium`
    width: 366px;
    min-height: 168px;
    height: auto;
    padding: 24px;
  `};
`;
