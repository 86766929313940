const colors = {
  primary: '#EA7555',
  primaryDark: '#CF5F4B',
  white: '#FFFFFF',
  lightGrey: '#E5E5E5',
  secondary: '#ffffff',
  charcoal: '#3E3A37',
  stone: '#F8F7F4',
  mac: '#00ACEF',
  macDark: '#008ABF',
  gray: '#888683',
  grayLight: '#AEABA8',
  grayDark: '#3E3A37',
};

const buttonCommon = {
  background: colors.white,
  color: colors.charcoal,
};

export const theme = {
  colors,
  text: {
    color: colors.white,
  },
  button: {
    primary: {
      normal: buttonCommon,
      hover: {
        ...buttonCommon,
        opacity: 0.8,
      },
      disabled: {
        ...buttonCommon,
        opacity: 0.6,
      },
    },
    secondary: {
      normal: {
        background: colors.charcoal,
        color: colors.white,
      },
    },
  },
  loader: {
    outerColor: colors.gray,
    innerColor: colors.charcoal,
  },
  nps: {
    background: colors.stone,
    question: {
      background: colors.primary,
    },
    fallback: {
      background: colors.primary,
    },
    submitted: {
      background: colors.primary,
    },
  },
  csat: {
    background: colors.stone,
    question: {
      background: colors.primary,
    },
    fallback: {
      background: colors.stone,
    },
    submitted: {
      background: colors.primary,
    },
  },
  notFound: {
    background: colors.primary,
  },
  icon: {
    primary: colors.primary,
    secondary: colors.charcoal,
    tertiary: colors.white,
  },
};
