/* eslint-disable react/require-default-props */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as Star } from '../../icons/star.svg';
import { theme } from '../../style';

interface StarProps {
  size?: number;
  stroke: { regular: string; dark: string };
  fill: string;
  isSelected?: boolean;
  onClick?: () => void;
}

export const RatingStar: React.FC<React.PropsWithChildren<StarProps>> = ({
  fill,
  size,
  stroke,
  isSelected = false,
  onClick,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <StyledStar
      stroke={isHovered && !isSelected ? stroke.dark : stroke.regular}
      fill={isSelected ? fill : theme.colors.white}
      width={size || 24}
      height={size || 24}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={onClick}
      data-testid="rating-star"
    />
  );
};

const StyledStar = styled(Star)`
  cursor: pointer;
  margin-bottom: 10px;
`;
