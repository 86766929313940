import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { createApolloDataClient } from '@venncity/data-client-ui';
import { ApolloProvider } from 'react-apollo';
import { NotFound } from '../../NotFound';
import { Nps } from '../../Nps';
import { Csat } from '../../Csat';

const apolloClient: any = createApolloDataClient({
  clientName: 'web-general-pages',
  clientVersion: process.env.REACT_APP_STAGE!,
  url: process.env.REACT_APP_GRAPHQL_URL,
  stage: process.env.REACT_APP_STAGE!,
});

const Routes = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get('t');

  return (
    <Switch>
      <Route path="/survey/:token/:firstQuestionAnswer?">
        <Nps />
      </Route>
      <Route path="/csat">{token ? <Csat token={token} /> : <Redirect to="/not-found" />}</Route>
      <Route path="/not-found" exact>
        <NotFound />
      </Route>
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default Routes;
