import { useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { vennAnalytics } from '@venncity/monitor-ui';
import { ANSWER_SURVEY, GET_NPS_SURVEYS } from '../queries/survey';
import {
  QuestionType,
  OnAnswerSurveyQuestionType,
  QuestionIdentifier,
  SurveyAnswer,
  SurveyQuestion,
} from '../types/survey';

export type Origin = 'venn_inbox' | 'email';
interface UseSurveyParams {
  responderEmail: string;
  firstQuestionAnswer: string;
  firstQuestionType: QuestionType;
  surveyId: string;
  origin: Origin;
}

export const useNpsSurvey = ({
  responderEmail,
  firstQuestionAnswer,
  firstQuestionType,
  surveyId,
  origin,
}: UseSurveyParams) => {
  const [isFullyAnswered, setIsFullyAnswered] = useState<boolean>(false);
  const [isFullySubmitted, setIsFullySubmitted] = useState<boolean>(false);
  const [loadingQuestionId, setLoadingQuestionId] = useState<string>();

  const [answerSurvey] = useMutation(ANSWER_SURVEY, {
    onCompleted: (data) => {
      setLoadingQuestionId(undefined);

      const {
        upsertQuestionResponse: {
          question: {
            text,
            survey: {
              status: { answers },
            },
          },
        },
      } = data;

      setIsFullyAnswered(answers.length >= actualQuestionsLength - openQuestions.length); // Open questions not mandatory

      if (text === 'SUBMIT') {
        setIsFullySubmitted(true);
      }
    },
    onError: () => {
      setLoadingQuestionId(undefined);
    },
  });

  const [surveyAnswers, setSurveyAnswers] = useState<{ [key: string]: string }>({});

  const onSyncClientSurveyAnswers = useCallback((serverAnswers: [SurveyAnswer]) => {
    const questionsAnswersMap = serverAnswers.reduce(
      (questionsAnswersMap: any, currAnswer: any) => ({
        ...questionsAnswersMap,
        [currAnswer.question.id]: currAnswer.value,
      }),
      {}
    );
    setSurveyAnswers(questionsAnswersMap);
  }, []);

  const { data, loading, error } = useQuery(GET_NPS_SURVEYS, {
    variables: { userMail: responderEmail, surveyId },
    onCompleted: ({ survey }) => {
      if (survey) {
        const {
          status: { answers, fullySubmitted },
        } = survey;
        setIsFullySubmitted(fullySubmitted);
        onSyncClientSurveyAnswers(answers);
      }
    },
  });

  const {
    survey: { questions, status },
  } = useMemo<{
    survey: {
      questions: SurveyQuestion[];
      status: { fullySubmitted: boolean };
    };
  }>(() => {
    if (error || !data?.survey) {
      return { survey: { questions: [] } };
    }
    return data;
  }, [data, error]);

  const getQuestion = useCallback(
    (questionIdentifier: QuestionIdentifier) => {
      return questions.find(
        (question) => question.metadata?.questionIdentify === questionIdentifier
      );
    },
    [questions]
  );

  const questionTypeToQuestionIdentifier = useCallback((questionType: QuestionType) => {
    switch (questionType) {
      case QuestionType.LANDLORD:
        return QuestionIdentifier.Landlord;
      case QuestionType.VENN:
        return QuestionIdentifier.Venn;
      case QuestionType.LEASE:
        return QuestionIdentifier.Renewal;
    }

    return undefined;
  }, []);

  const sortByQuestionType = useCallback(
    (leadQuestionType: QuestionType) => {
      const questionTypeAsQuestionIdentifier = questionTypeToQuestionIdentifier(leadQuestionType);

      return (q1: SurveyQuestion, q2: SurveyQuestion) => {
        if (q1 && q1.metadata?.questionIdentify === questionTypeAsQuestionIdentifier) {
          // q1 will be sorted higher than q2
          return -1;
        }
        if (q2 && q2.metadata?.questionIdentify === questionTypeAsQuestionIdentifier) {
          // q2 will be sorted higher than q1
          return 1;
        }
        return 0;
      };
    },
    [questionTypeToQuestionIdentifier]
  );

  const { npsQuestions, yesNoQuestion, submitQuestion, actualQuestionsLength, openQuestions } =
    useMemo(() => {
      const submitQuestion = questions.find((question) => question.text === 'SUBMIT')!;

      const landlordQuestion = getQuestion(QuestionIdentifier.Landlord);
      const vennQuestion = getQuestion(QuestionIdentifier.Venn);
      const yesNoQuestion = getQuestion(QuestionIdentifier.Renewal);
      const vennValuesOpenQuestion = getQuestion(QuestionIdentifier.VennValuesOpenQuestion);
      const improvementsOpenQuestion = getQuestion(QuestionIdentifier.ImprovementsOpenQuestion);

      const npsQuestions = [landlordQuestion, vennQuestion]
        .filter((question): question is SurveyQuestion => question !== undefined)
        .sort(sortByQuestionType(firstQuestionType));

      const openQuestions = [
        ...(vennValuesOpenQuestion ? [vennValuesOpenQuestion] : []),
        ...(improvementsOpenQuestion ? [improvementsOpenQuestion] : []),
      ];

      return {
        npsQuestions,
        yesNoQuestion,
        openQuestions,
        submitQuestion,
        actualQuestionsLength: openQuestions.length + npsQuestions.length + (yesNoQuestion ? 1 : 0),
      };
    }, [getQuestion, questions, firstQuestionType, sortByQuestionType]);

  const handleAnswerQuestion: OnAnswerSurveyQuestionType = useCallback(
    (questionId, answer) => {
      setLoadingQuestionId(questionId);

      setSurveyAnswers((prevState) => ({
        ...prevState,
        [questionId]: answer,
      }));
      setIsFullyAnswered(false);

      answerSurvey({
        variables: { questionId, responderEmail, answer, origin },
      });
    },
    [answerSurvey, origin, responderEmail]
  );

  const handleSubmitSurvey = useCallback(async () => {
    await handleAnswerQuestion(submitQuestion.id, '');
    vennAnalytics.track(
      {
        domain: 'NpsSurveyWebPage',
        object: 'NpsSurvey',
        action: 'Submitted',
      },
      {
        email: responderEmail,
      }
    );
  }, [handleAnswerQuestion, responderEmail, submitQuestion]);

  // answer the first question by the url params on first load
  useEffect(() => {
    if (!loading && firstQuestionAnswer) {
      const firstQuestion = questions.find(
        (question) =>
          question.metadata?.questionIdentify ===
          questionTypeToQuestionIdentifier(firstQuestionType)
      );
      if (firstQuestion) {
        handleAnswerQuestion(firstQuestion.id, firstQuestionAnswer);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  return {
    error: error || data?.survey === null,
    loading,
    loadingQuestionId,
    isFullySubmitted,
    fullySubmittedOnLoad: status?.fullySubmitted,
    isFullyAnswered,
    npsQuestions,
    yesNoQuestion,
    openQuestions,
    submitQuestion,
    surveyAnswers,
    handleAnswerQuestion,
    handleSubmitSurvey,
  };
};
