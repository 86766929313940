import styled from 'styled-components';
import { paragraph3 } from '../../../shared/components';
import media from '../../../shared/style/media';

// this component is due to design limitations
export const CheckButtonText = styled.p`
  font-weight: normal;
  font-size: 17px;
  line-height: 22px;
  ${media.mobile`
    ${paragraph3};
  `};
`;
