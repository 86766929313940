import React from 'react';
import { ErrorScreen } from '../../shared/components';

class ErrorBoundary extends React.Component {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    return { hasError: true };
  }

  render() {
    const { hasError } = this.state as any;
    const { children } = this.props;

    return <>{hasError ? <ErrorScreen /> : children}</>;
  }
}

export default ErrorBoundary;
