import styled from 'styled-components';
import { Button } from './Button';
import media from '../../style/media';
import { generateButtonCommonCss } from '../../style/commonCssGenerators';

export const CheckButton = styled(Button)<{ selected?: boolean }>`
  width: 40px;
  ${({ selected }) => generateButtonCommonCss(selected ? 'secondary' : 'primary')};

  ${media.mobile`
    width: 50px;
  `};
`;
