/* eslint-disable import/no-dynamic-require */
import styled, { css } from 'styled-components';
import jwt from 'jwt-decode';
import { useParams } from 'react-router';
import React, { useCallback, useMemo, useState } from 'react';
import { ErrorScreen, FlexColumn, FlexColumnCenter, Loader } from '../../shared/components';
import { useCsatSurvey } from '../hooks/useCsatSurvey';
import { ReactComponent as VennLogo } from '../../shared/icons/venn-logo-text-only.svg';
import { ReactComponent as MacComponent } from '../../shared/icons/mac-logo.svg';
import media from '../../shared/style/media';
import { ThankYou } from './ThankYou';
import { Form, UserAnswers } from './Form';
import { isAllQuestionAnswered } from '../utils';

export const PortfolioContext = React.createContext<string>('venn');
export const MetadataContext = React.createContext<Record<string, string>>({});

export type CsatParams = {
  isMac: boolean;
  userId: string;
  surveyId: string;
  responderEmail: string;
  firstName: string;
  lastName: string;
  hoodName: string;
};

interface LineBreakProps {
  shouldHide?: boolean;
}

const Csat = ({ token }: { token: string | null }) => {
  const [isSubmitted, setIsSubmitted] = useState(false);

  const { isMac, userId, surveyId, responderEmail, firstName, lastName, hoodName }: CsatParams =
    useMemo(() => jwt(token!), [token]);

  const { error, loading, survey, submitCsat } = useCsatSurvey({
    isMac,
    userId,
    surveyId,
    responderEmail,
    firstName,
    lastName,
    hoodName,
  });

  const handleSubmit = useCallback(
    (userAnswers: UserAnswers, surveyName: string) => {
      if (isAllQuestionAnswered(survey.questions, userAnswers)) {
        setIsSubmitted(true);
        submitCsat(userAnswers, surveyName);
      }
    },
    [submitCsat, survey.questions]
  );

  const shouldThankyou = useMemo(
    () => isSubmitted || survey?.fullySubmitted,
    [isSubmitted, survey.fullySubmitted]
  );

  const thankYouTitle = useMemo(() => {
    if (survey?.fullySubmitted) {
      return 'Your survey has already been submitted.';
    }

    return 'Thank you!';
  }, [survey.fullySubmitted]);

  const thankYouDescription = useMemo(() => {
    if (survey?.fullySubmitted) {
      return 'Once again, thank you!';
    }

    return 'Your feedback means the world to us.<br />We appreciate your willingness to share your thoughts.';
  }, [survey.fullySubmitted]);

  if (loading) {
    return (
      <Loading>
        <Loader />
      </Loading>
    );
  }

  if (error || !survey.questions.length) {
    return <ErrorScreen />;
  }

  return (
    <PortfolioContext.Provider value={isMac ? 'mac' : 'venn'}>
      <MetadataContext.Provider value={{ firstName, lastName, hoodName, responderEmail }}>
        <CsatWrapper>
          <CsatHeader>{isMac ? <MacComponent /> : <VennLogo />}</CsatHeader>
          <LineBreak shouldHide />
          {shouldThankyou ? (
            <ThankYou title={thankYouTitle} description={thankYouDescription} />
          ) : (
            <Form survey={survey} handleSubmit={handleSubmit} />
          )}

          <LineBreak />
          <CsatFooter>
            <Label>powered by:</Label>
            <VennLogo width={48} height={13} />
            <CopyRights>
              Copyright © {new Date().getFullYear()} Venn Ltd, All rights reserved.
              <br />
              12 Vestry St, New York, NY 10013, US
            </CopyRights>
          </CsatFooter>
        </CsatWrapper>
      </MetadataContext.Provider>
    </PortfolioContext.Provider>
  );
};

const CsatWrapper = styled(FlexColumn)`
  max-width: 700px;
  display: flex;
  margin: auto;
  align-items: center;
  color: ${({ theme }) => theme.colors.charcoal}};
  ${media.mobileMedium`
    width: 390px;
    padding: 0 24px;
  `};
  min-height: 100vh;
`;

const CsatHeader = styled.div`
  display: flex;
  justify-content: center;
  margin: 32px 0;
  ${media.mobileMedium`
    margin: 24px 0;
  `};
`;

const CsatFooter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px 0;
  flex-direction: column;
`;

const CopyRights = styled.div`
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.3px;
  color: ${({ theme }) => theme.colors.charcoal}};
  text-align: center;
  margin-top: 16px;
`;

const LineBreak = styled.div.attrs(({ shouldHide }: LineBreakProps) => ({
  shouldHide: shouldHide || false,
}))<LineBreakProps>`
  width: 95vw;
  height: 1px;
  background-color: #aeaba8;
  ${({ shouldHide }) => shouldHide && transparent};
`;

const Loading = styled(FlexColumnCenter)`
  background-color: ${({ theme }: any) => theme.csat.fallback.background};
  height: 100vh;
  padding: 0 40px;
  text-align: center;
`;

const transparent = css`
  ${media.mobileMedium`
    display: none;
  `};
`;

const Label = styled.div`
  font-size: 12px;
  margin-bottom: 16px;
`;

export default Csat;
