/* eslint-disable react/no-array-index-key */
/* eslint-disable react/require-default-props */
import React, { DetailedHTMLProps, TextareaHTMLAttributes, useState } from 'react';
import styled from 'styled-components';

interface RatingMeterProps {
  placeholder?: string;
  maxSize?: number;
  onChange?: (text: string) => void;
}

export const Input: React.FC<React.PropsWithChildren<RatingMeterProps>> = ({
  placeholder,
  maxSize,
  onChange,
}) => {
  const [text, setText] = useState('');

  const handleOnChange = (value: string) => {
    if (!maxSize || value.length <= maxSize) {
      setText(value);
    }
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <Wrapper>
      <TextArea
        value={text}
        placeholder={placeholder || ''}
        onChange={(e) => handleOnChange(e.target.value)}
        maxSize={maxSize}
      />
      {maxSize && (
        <Label maxSize={maxSize} value={text}>
          {text.length}/{maxSize}
        </Label>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.charcoal};
`;

interface TextAreaProps
  extends DetailedHTMLProps<TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement> {
  value: string;
  maxSize?: number;
}

const TextArea = styled.textarea<TextAreaProps>`
  height: 74px;
  ::placeholder {
    color: ${({ theme }) => theme.colors.charcoal};
    opacity: 0.4;
  }
  :focus {
    color: ${({ theme }) => theme.colors.darkGray};
  }
  resize: none;
  border-color: ${({ theme }) => theme.colors.grayLight};
  border-radius: 10px;
  padding: 8px 16px;
  outline: 0px;
  touch-action: manipulation;

  ${({ value, maxSize }) => maxSize && value.length >= maxSize && `border: 1px solid #B23026;`}
`;

const Label = styled.div<TextAreaProps>`
  font-size: 11px;
  color: ${({ theme }) => theme.csat.question.text};
  opacity: 0.4;
  align-self: flex-end;
  margin: 8px 0 0;

  ${({ value, maxSize }) => maxSize && value.length >= maxSize && `color: #B23026; opacity: 1;`}
`;
