import { FC } from 'react';
import styled from 'styled-components';
import { FlexCenter } from '../../../shared/components';

interface OpenQuestionOptionProps {
  id: string;
  answerQuestion: (value: string) => void;
}

const OpenQuestionOption: FC<OpenQuestionOptionProps> = ({ id, answerQuestion }) => {
  return (
    <Wrapper>
      <TextArea id={id} onBlur={(event) => answerQuestion(event.target.value)} />
    </Wrapper>
  );
};
const Wrapper = styled(FlexCenter)`
  justify-content: space-around;
  width: 80%;
`;

const TextArea = styled.textarea`
  border-radius: 10px;
  border: transparent;
  padding: 8px;
  ${({ disabled }) =>
    disabled &&
    `
    pointer-event: none;
    cursor: not-allowed;
    opacity: 0.9;
  `}
  font-size: 16px;
  resize: none;
  height: 100px;
  width: 100%;
`;

export default OpenQuestionOption;
