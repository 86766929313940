import { gql } from '@apollo/client';

export const GET_CSAT_SURVEY = gql`
  query fetchCsat($userMail: String!, $surveyId: ID!) {
    survey(where: { id: $surveyId }) {
      id
      name
      isExpired
      hoodId
      status(where: { userMail: $userMail }) {
        fullySubmitted
        answers {
          id
          value
          question {
            id
          }
        }
      }
      questions(where: { deleted: 0 }, first: 10) {
        id
        text
        options
        order
      }
    }
  }
`;

export const SUBMIT_CSAT = gql`
  mutation submitCsat(
    $responses: [SurveyQuestionResponseCreateInput]
    $surveyName: String!
    $data: SurveyUserActionCreateInput!
  ) {
    answerSurvey(responses: $responses, surveyName: $surveyName) {
      id
    }
    createSurveyUserAction(data: $data) {
      id
    }
  }
`;
