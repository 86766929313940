import { FC, useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { SubmitButton, FlexColumnCenter, paragraph3Bold } from '../../../shared/components';
import media from '../../../shared/style/media';
import { SurveyQuestion } from '../../types/survey';
import Question from './Question';

interface QuestionsProps {
  npsQuestions: SurveyQuestion[];
  openQuestions: SurveyQuestion[];
  yesNoQuestion: SurveyQuestion | undefined;
  submitQuestion: SurveyQuestion;
  surveyAnswers: { [key: string]: string };
  isFullyAnswered: boolean;
  loadingQuestionId: string | undefined;
  onAnswerQuestion: (questionId: string, answer: string) => void;
  onSubmitSurvey: () => void;
}

export const QuestionsList: FC<QuestionsProps> = ({
  npsQuestions,
  openQuestions,
  yesNoQuestion,
  submitQuestion,
  surveyAnswers,
  isFullyAnswered,
  loadingQuestionId,
  onAnswerQuestion,
  onSubmitSurvey,
}) => {
  const submitRef: any = useRef();
  const [isSubmitted, setIsSubmmited] = useState(false);

  useEffect(() => {
    if (isFullyAnswered) {
      submitRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [isFullyAnswered]);

  const onSubmit = useCallback(() => {
    onSubmitSurvey();
    setIsSubmmited(true);
  }, [onSubmitSurvey]);

  return (
    <Wrapper data-test-id="questions-list">
      {npsQuestions.map((question: SurveyQuestion) => (
        <Question
          key={question.id}
          onAnswer={onAnswerQuestion}
          question={question}
          type="NPS"
          answer={surveyAnswers[question.id]}
          loadingQuestionId={loadingQuestionId}
          disabled={isSubmitted}
        />
      ))}
      {openQuestions.map((question: SurveyQuestion) => (
        <Question
          key={question.id}
          onAnswer={onAnswerQuestion}
          question={question}
          type="OPEN_QUESTION"
          answer={surveyAnswers[question.id]}
          loadingQuestionId={loadingQuestionId}
          disabled={isSubmitted}
        />
      ))}
      {yesNoQuestion && (
        <Question
          onAnswer={onAnswerQuestion}
          question={yesNoQuestion}
          type="YES_NO"
          answer={surveyAnswers[yesNoQuestion.id]}
          loadingQuestionId={loadingQuestionId}
          disabled={isSubmitted}
        />
      )}
      <SubmitButton
        data-test-id="submit-survey"
        ref={submitRef}
        disabled={!isFullyAnswered}
        onClick={onSubmit}
      >
        <SubmitText>{submitQuestion.text}</SubmitText>
      </SubmitButton>
    </Wrapper>
  );
};

const Wrapper = styled(FlexColumnCenter)`
  width: 770px;
  margin-bottom: 20px;
  ${media.mobile`
    width: 100vw;
    margin-bottom: 0;
  `};
`;

const SubmitText = styled.h3`
  ${media.mobile`
    ${paragraph3Bold};
    font-size: 20px;
  `};
`;
