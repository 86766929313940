/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/require-default-props */
import { FC } from 'react';
import styled from 'styled-components';
import { flexColumn, flexColumnCenter, FlexColumnCenter, H3Bold } from '../../shared/components';
import { ReactComponent as ThankYouIcon } from '../../shared/icons/plant.svg';
import { ReactComponent as SubmittedIcon } from '../../shared/icons/loving.svg';
import { VennVendorLogos } from './VennVendorLogos';
import media from '../../shared/style/media';

interface Props {
  alreadySubmitted?: boolean;
  userFirstName?: string;
  hoodName: string;
}

export const SubmittedNps: FC<Props> = ({ alreadySubmitted, userFirstName, hoodName }) => (
  <Wrapper>
    <FlexColumnCenter>
      {alreadySubmitted ? <SubmittedIcon /> : <ThankYouIcon />}
      <SubmittedTitle>
        {alreadySubmitted ? (
          "You have already completed the survey. We'll send you another one in a few months. Thank you once again for helping us to improve."
        ) : (
          <>
            <span>Thank you {userFirstName} for taking our survey.</span>
            <span>We appreciate you.</span>
          </>
        )}
      </SubmittedTitle>
    </FlexColumnCenter>

    <Logos hoodName={hoodName} />
  </Wrapper>
);

const Wrapper = styled(FlexColumnCenter)`
  justify-content: flex-end;
  background-color: ${({ theme }) => theme.nps.submitted.background};
  height: 100vh;

  > div:first-child {
    width: 50%;
    max-width: 780px;
    flex: 1;
    ${media.mobile`
      width: 100vw;
      padding: 0 40px;
    `};
  }
`;

const SubmittedTitle = styled(H3Bold)`
  ${flexColumnCenter};
  text-align: center;
  margin-top: 48px;
`;

const Logos = styled(VennVendorLogos)`
  width: 100%;
  margin-bottom: 60px;
  padding: 0;
`;
