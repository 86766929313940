import { ApolloProvider } from '@apollo/client';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter } from 'react-router-dom';
import { createApolloDataClient } from '@venncity/data-client-ui';
import Routes from './Routes';
import { GlobalStyle, theme } from '../../shared/style';
import ErrorBoundary from './ErrorBoundary';

const apolloClient: any = createApolloDataClient({
  clientName: 'web-general-pages',
  clientVersion: process.env.REACT_APP_STAGE!,
  url: process.env.REACT_APP_GRAPHQL_URL,
  stage: process.env.REACT_APP_STAGE!,
});

interface Props {}

const App = (props: Props) => (
  <ApolloProvider client={apolloClient}>
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <BrowserRouter>
        <ErrorBoundary>
          <Routes />
        </ErrorBoundary>
      </BrowserRouter>
    </ThemeProvider>
  </ApolloProvider>
);

export default App;
