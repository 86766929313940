// write component for submit button

import React, { useContext } from 'react';
import styled from 'styled-components';
import media from '../../shared/style/media';
import { PortfolioContext } from './Csat';
import { theme } from '../../shared/style';

interface SubmitButtonProps {
  text: string;
  isDisabled: boolean;
  onClick: () => void;
}

export const SubmitButton: React.FC<SubmitButtonProps> = ({ text, isDisabled = true, onClick }) => {
  const portfolio = useContext(PortfolioContext);
  return (
    <Button
      onClick={onClick}
      bgcolor={portfolio === 'venn' ? theme.colors.primary : theme.colors.mac}
      bgcolorHover={portfolio === 'venn' ? theme.colors.primaryDark : theme.colors.macDark}
      disabled={isDisabled}
    >
      {text}
    </Button>
  );
};

const Button = styled.button.attrs(
  ({ bgcolor, bgcolorHover }: { bgcolor: string; bgcolorHover: string }) => ({
    bgcolor: bgcolor || 'white',
    bgcolorHover: bgcolorHover || 'white',
  })
)`
  width: 385px;
  height: 48px;
  border: 0;
  border-radius: 10px;
  background-color: ${({ bgcolor }: any) => bgcolor};
  color: ${({ theme }) => theme.colors.white};
  font-size: 16px;
  font-family: MarkPro-Bold;
  font-weight: 700;
  &:not(:disabled):hover {
    background-color: ${({ bgcolorHover }: any) => bgcolorHover};
    cursor: pointer;
  }
  &:disabled {
    color: rgba(255, 255, 255, 0.4);
    &:hover {
    }
  }

  ${media.mobileMedium`
    width: 366px;
    padding: 11px 16px;
`};
`;
