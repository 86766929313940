/* eslint-disable react/no-array-index-key */
/* eslint-disable react/require-default-props */
import React, { useState } from 'react';
import styled from 'styled-components';
import { RatingStar } from './RatingStar';
import { theme } from '../../style';
import media from '../../style/media';

interface RatingMeterProps {
  maxRating?: number;
  size?: number;
  fill?: string;
  stroke?: { regular: string; dark: string };
  onChange?: (rating: number) => void;
}

export const RatingMeter: React.FC<React.PropsWithChildren<RatingMeterProps>> = ({
  maxRating = 5,
  size = 24,
  fill = theme.colors.primary,
  stroke = { regular: theme.colors.primary, dark: theme.colors.primaryDark },
  onChange,
}) => {
  const [rating, setRating] = useState(-1);
  const starsArray = Array(maxRating).fill('');

  const onStarPress = (index: number) => {
    setRating(index);
    onChange?.(index);
  };

  return (
    <RatingMeterWrapper>
      {starsArray.map((_, index) => {
        const isLastItem = starsArray.length - 1 === index;
        return (
          <RatingMeterItem key={index} isLastItem={isLastItem}>
            <RatingStar
              key={index}
              size={size}
              fill={fill}
              stroke={stroke}
              isSelected={index <= rating}
              onClick={() => onStarPress(index)}
            />
            {index === 0 && (
              <Label>
                Strongly
                <br />
                disagree
              </Label>
            )}
            {index + 1 === starsArray.length && (
              <Label>
                Strongly
                <br />
                agree
              </Label>
            )}
          </RatingMeterItem>
        );
      })}
    </RatingMeterWrapper>
  );
};

const RatingMeterWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const RatingMeterItem = styled.div.attrs(({ isLastItem }: { isLastItem: boolean }) => ({
  isLastItem: isLastItem || false,
}))`
  margin-right: ${({ isLastItem }) => (isLastItem ? '0' : '24px')};
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

const Label = styled.div`
  color: theme.colors.charcoal;
  position: absolute;
  bottom: -24px;
  font-size: 12px;
  white-space: nowrap;
  ${media.mobileMedium`
    font-size: 12px;
`};
`;
