/* eslint-disable react/require-default-props */
import styled from 'styled-components';
import { FC, useMemo } from 'react';
import { FlexCenter } from '../../shared/components';
import { ReactComponent as VennLogo } from '../../shared/icons/venn-logo.svg';
import { ReactComponent as PlusLogoBase } from '../../shared/icons/plus.svg';
import { ReactComponent as VennLogoText } from '../../shared/icons/venn-logo-text.svg';
import media from '../../shared/style/media';
import { DynamicIconRenderer } from '../../shared/components/DynamicIconRenderer';

interface Props {
  hoodName: string;
  className?: string;
}

export const VennVendorLogos: FC<Props> = ({ hoodName, className }) => {
  const hoodIconName = useMemo(() => hoodName.split(' ').join('-'), [hoodName]);

  const hoodIcon = useMemo(() => DynamicIconRenderer({ fileName: hoodIconName }), [hoodIconName]);

  return (
    <Wrapper className={className}>
      {hoodName && hoodIcon ? (
        <>
          {hoodIcon}
          <PlusLogo />
          <VennLogo />
        </>
      ) : (
        <VennLogoText />
      )}
    </Wrapper>
  );
};

const Wrapper = styled(FlexCenter)`
  padding: 30px 0;
  width: 110px;

  ${media.mobile`
    width: 100vw;
    padding-top: 60px;
    padding-bottom: 20px;
  
    ${({ theme }: any) => `
      background-color: ${theme.icon.primary};
    `}
    `};
`;

const PlusLogo = styled(PlusLogoBase)`
  margin: 0 20px;
`;
