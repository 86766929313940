import { css } from 'styled-components';

export const defaultMediaBreakpoints: { [key: string]: string } = {
  mobile: '768px',
  mobileSmall: '314px',
  mobileMedium: '428px',
};

const media = Object.keys(defaultMediaBreakpoints).reduce(
  (styles: { [key: string]: any }, val: string) => {
    styles[val] = (...args: [any, any]) => css`
      @media (max-width: ${defaultMediaBreakpoints[val]}) {
        ${css(...args)};
      }
    `;

    return styles;
  },
  {}
);

export default media;
