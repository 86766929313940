export type SurveyQuestion = {
  id: string;
  text: string;
  metadata: {
    questionIdentify: QuestionIdentifier;
  };
};

export type SurveyAnswer = {
  id: string;
  value: string;
  question: {
    id: string;
  };
};

export type OnAnswerSurveyQuestionType = (questionId: string, answer: string) => void;

export enum QuestionIdentifier {
  Renewal = 'Renewal',
  Landlord = 'Landlord',
  Venn = 'Venn',
  VennValuesOpenQuestion = 'VennValuesOpenQuestion',
  ImprovementsOpenQuestion = 'ImprovementsOpenQuestion',
}

export enum QuestionType {
  LEASE = 'LEASE',
  LANDLORD = 'LANDLORD',
  VENN = 'VENN',
}
