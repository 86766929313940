/* eslint-disable import/no-dynamic-require */
import { FC } from 'react';

interface DynamicIconRendererProps {
  fileName: string;
}

export const DynamicIconRenderer: FC<DynamicIconRendererProps> = ({ fileName }) => {
  // eslint-disable-next-line import/no-dynamic-require
  try {
    const iconComponent = require(`../../icons/svgAsComponent/${fileName}`);
    return iconComponent.default();
  } catch (e) {
    return null;
  }
};
