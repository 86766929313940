import styled from 'styled-components';
import { Card } from '../../shared/components';
import { ReactComponent as Heart } from '../../shared/icons/heart.svg';

export const ThankYou = ({ title, description }: { title: string; description: string }) => {
  return (
    <ThankYouWrapper>
      <Card>
        <Title>{title}</Title>
        <Description dangerouslySetInnerHTML={{ __html: description }} />
        <Heart width={24} height={24} />
      </Card>
    </ThankYouWrapper>
  );
};

const ThankYouWrapper = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 24px 0 auto;
`;

const Title = styled.div`
  font-size: 24px;
  font-family: MarkPro-Bold;
  margin-bottom: 32px;
`;

const Description = styled.div`
  font-size: 16px;
  margin-bottom: 32px;
`;
