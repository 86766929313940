import React from 'react';
import styled from 'styled-components';
import { FlexColumn, FlexColumnCenter } from '../Flex';
import { H3Bold } from '../Typography';
import { ReactComponent as VennLogo } from '../../icons/venn-logo-text.svg';

export const ErrorScreen = () => (
  <Wrapper>
    <FlexColumnCenter>
      <H3Bold>There was an error while trying to load this page.</H3Bold>{' '}
      <H3Bold>Please try again later</H3Bold>
    </FlexColumnCenter>
    <VennLogo />
  </Wrapper>
);

const Wrapper = styled(FlexColumn)`
  align-items: center;
  background-color: ${({ theme }: any) => theme.nps.fallback.background};
  height: 100vh;
  padding: 40px;
  text-align: center;
  > div:first-child {
    flex: 1;
  }
`;
