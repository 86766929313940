import styled from 'styled-components';
import { FlexColumn, FlexColumnCenter, H2Bold } from '../../shared/components';
import { ReactComponent as NotFoundIcon } from '../../shared/icons/not-found.svg';
import { ReactComponent as VennLogo } from '../../shared/icons/venn-logo-text.svg';

const NotFound = () => (
  <Wrapper>
    <FlexColumnCenter>
      <H2Bold>Page not found...</H2Bold>
      <StyledNotFoundIcon />
    </FlexColumnCenter>
    <VennLogo />
  </Wrapper>
);

const Wrapper = styled(FlexColumn)`
  align-items: center;
  height: 100vh;
  padding: 40px;
  background-color: ${({ theme }) => theme.notFound.background};
  > div {
    flex: 1;
  }
`;

const StyledNotFoundIcon = styled(NotFoundIcon)`
  width: 200px;
  height: 200px;
  margin-top: 80px;
`;

export default NotFound;
