import { FC, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { FlexColumn, header3Medium, Loader } from '../../../shared/components';
import { OnAnswerSurveyQuestionType, SurveyQuestion } from '../../types/survey';
import { YesNoOptions } from './YesNoOptions';
import { NpsOptions } from './NpsOptions';
import media from '../../../shared/style/media';
import OpenQuestionOption from './OpenQuestionOption';

interface QuestionProps {
  question: SurveyQuestion;
  type: 'NPS' | 'YES_NO' | 'OPEN_QUESTION';
  answer: string | undefined;
  loadingQuestionId: string | undefined;
  onAnswer: OnAnswerSurveyQuestionType;
  disabled: boolean;
}

const Question: FC<QuestionProps> = ({
  question,
  type,
  answer,
  loadingQuestionId,
  onAnswer,
  disabled,
}) => {
  const handleAnswer = useCallback(
    (answer) => {
      onAnswer(question.id, answer);
    },
    [onAnswer, question]
  );

  const isLoadingQuestionAnswer = useMemo(
    () => loadingQuestionId === question.id,
    [loadingQuestionId, question]
  );

  const renderQuestion = useMemo(
    () => (typeOfQuestion: QuestionProps['type']) => {
      switch (typeOfQuestion) {
        case 'YES_NO': {
          return (
            <YesNoOptions
              onSelectOption={handleAnswer}
              selectedOption={answer}
              disabled={isLoadingQuestionAnswer || disabled}
            />
          );
        }
        case 'NPS': {
          return (
            <NpsOptions
              onSelectOption={handleAnswer}
              selectedOption={answer}
              disabled={isLoadingQuestionAnswer || disabled}
            />
          );
        }
        case 'OPEN_QUESTION': {
          return (
            <OpenQuestionOption
              id={question.metadata.questionIdentify}
              answerQuestion={handleAnswer}
            />
          );
        }
        default:
          return null;
      }
    },
    [answer, handleAnswer, disabled, isLoadingQuestionAnswer, question]
  );

  return (
    <Wrapper>
      {isLoadingQuestionAnswer && <StyledLoader />}
      <Title>{question.text}</Title>
      {renderQuestion(type)}
    </Wrapper>
  );
};

const Title = styled.h2`
  font-weight: normal;
  margin-bottom: 28px;
  width: 436px;
  text-align: center;
  ${media.mobile`
    ${header3Medium};
      margin-bottom: 40px;
      width: 100%;
      padding: 0 10vw;
    `};
`;

const Wrapper = styled(FlexColumn)`
  position: relative;
  align-items: center;
  border-radius: 40px;
  background-color: ${({ theme }) => theme.nps.question.background};
  width: 100%;
  padding: 30px;
  margin-bottom: 20px;

  ${media.mobile`
    margin-bottom: 2px;
    width: 100vw;
    border-radius: 0;
    padding: 80px 0;
  `};
`;

const StyledLoader = styled(Loader)`
  position: absolute;
  top: 20px;
  right: 20px;
  width: 30px;
  height: 30px;
  border-width: 4px;
`;

export default Question;
