/* eslint-disable react/require-default-props */
import { FC, useCallback } from 'react';
import styled from 'styled-components';
import { FlexCenter, CheckButton } from '../../../shared/components';
import { CheckButtonText } from './CheckButtonText';

const ANSWER_OPTIONS = {
  YES: 'yes',
  NO: 'no',
};
interface YesNoOptionsProps {
  selectedOption: string | undefined;
  disabled?: boolean;
  onSelectOption: (value: string) => void;
}

export const YesNoOptions: FC<YesNoOptionsProps> = ({
  selectedOption,
  disabled,
  onSelectOption,
}) => {
  const handleYesClick = useCallback(() => {
    onSelectOption(ANSWER_OPTIONS.YES);
  }, [onSelectOption]);

  const handleNoClick = useCallback(() => {
    onSelectOption(ANSWER_OPTIONS.NO);
  }, [onSelectOption]);

  return (
    <Wrapper>
      <StyledButton
        onClick={handleYesClick}
        selected={selectedOption === ANSWER_OPTIONS.YES}
        disabled={disabled}
      >
        <CheckButtonText>Yes</CheckButtonText>
      </StyledButton>
      <StyledButton
        onClick={handleNoClick}
        selected={selectedOption === ANSWER_OPTIONS.NO}
        disabled={disabled}
      >
        <CheckButtonText>No</CheckButtonText>
      </StyledButton>
    </Wrapper>
  );
};

const Wrapper = styled(FlexCenter)`
  justify-content: space-around;
  width: 100%;
  padding: 0 190px;
  > button {
    margin: 0 5px;
  }
`;

const StyledButton = styled(CheckButton)`
  min-width: 160px;
`;
