/* eslint-disable import/no-dynamic-require */
import styled from 'styled-components';
import jwt from 'jwt-decode';
import { useParams } from 'react-router';
import { useMemo } from 'react';
import { ErrorScreen, FlexColumn, FlexColumnCenter, Loader } from '../../shared/components';
import { Origin, useNpsSurvey } from '../hooks/useNpsSurvey';
import { VennVendorLogos } from './VennVendorLogos';
import { SubmittedNps } from './SubmittedNps';
import { QuestionsList } from './questions/QuestionsList';
import media from '../../shared/style/media';
import { useQueryParams } from '../../shared/hooks/useQueryParams';
import { QuestionType } from '../types/survey';

type TokenParamsType = {
  email: string;
  firstName: string;
  hoodName: string;
  surveyId: string;
  firstQuestionType: QuestionType;
};

const Nps = () => {
  const { token, firstQuestionAnswer }: { token: string; firstQuestionAnswer: string } =
    useParams();
  const queryParams = useQueryParams();

  const {
    email: responderEmail,
    firstName,
    hoodName,
    surveyId,
    firstQuestionType,
  }: TokenParamsType = useMemo(() => jwt(token), [token]);

  const {
    error,
    loading,
    loadingQuestionId,
    isFullySubmitted,
    fullySubmittedOnLoad,
    npsQuestions,
    yesNoQuestion,
    openQuestions,
    submitQuestion,
    surveyAnswers,
    isFullyAnswered,
    handleAnswerQuestion,
    handleSubmitSurvey,
  } = useNpsSurvey({
    responderEmail,
    firstQuestionAnswer,
    firstQuestionType,
    surveyId,
    origin: (queryParams.get('utm_source') as Origin | null) || 'email',
  });

  if (error) {
    return <ErrorScreen />;
  }
  if (loading) {
    return (
      <Loading>
        <Loader />
      </Loading>
    );
  }

  if (fullySubmittedOnLoad || isFullySubmitted) {
    return (
      <SubmittedNps
        alreadySubmitted={fullySubmittedOnLoad}
        userFirstName={firstName}
        hoodName={hoodName}
      />
    );
  }

  return (
    <Wrapper>
      <Logos hoodName={hoodName} />

      <QuestionsList
        npsQuestions={npsQuestions}
        yesNoQuestion={yesNoQuestion}
        openQuestions={openQuestions}
        submitQuestion={submitQuestion}
        surveyAnswers={surveyAnswers}
        isFullyAnswered={isFullyAnswered}
        loadingQuestionId={loadingQuestionId}
        onAnswerQuestion={handleAnswerQuestion}
        onSubmitSurvey={handleSubmitSurvey}
      />
    </Wrapper>
  );
};

const Wrapper = styled(FlexColumn)`
  align-items: center;
  background-color: ${({ theme }) => theme.nps.background};
  min-height: 100vh;
  overflow-x: hidden;
`;

const Logos = styled(VennVendorLogos)`
  ${({ theme }) => `
      path {fill: ${theme.icon.secondary}; stroke: ${theme.icon.secondary}};
  `}

  ${media.mobile`
    ${({ theme }: any) => `
        path {fill: ${theme.icon.tertiary}; stroke: ${theme.icon.tertiary}};
    `}
  `};
`;

const Loading = styled(FlexColumnCenter)`
  background-color: ${({ theme }: any) => theme.nps.fallback.background};
  height: 100vh;
  padding: 0 40px;
  text-align: center;
`;

export default Nps;
