import { gql } from '@apollo/client';

export const GET_NPS_SURVEYS = gql`
  query fetchNpsSurvey($userMail: String!, $surveyId: ID!) {
    survey(where: { id: $surveyId }) {
      id
      name
      isPublicAccess
      isExpired
      hoodId
      status(where: { userMail: $userMail }) {
        fullySubmitted
        answers {
          id
          value
          question {
            id
          }
        }
      }
      questions(where: { deleted: 0 }, first: 10) {
        id
        text
        metadata
      }
    }
  }
`;

export const ANSWER_SURVEY = gql`
  mutation upsertSurveyResponse(
    $questionId: ID!
    $responderEmail: String!
    $answer: String!
    $origin: String
  ) {
    upsertQuestionResponse(
      data: {
        value: $answer
        question: { connect: { id: $questionId } }
        responderEmail: $responderEmail
        origin: $origin
      }
    ) {
      responderId
      value
      question {
        id
        text
        survey {
          status(where: { userMail: $responderEmail }) {
            answers {
              value
            }
          }
        }
      }
    }
  }
`;
