import { useCallback, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { vennAnalytics } from '@venncity/monitor-ui';
import { CsatParams } from '../components/Csat';
import { GET_CSAT_SURVEY, SUBMIT_CSAT } from '../queries';
import { UserAnswers } from '../components/Form';

export type QuestionType = {
  text: string;
  id: string;
  options: object;
  order: number;
};

export type SurveyType = {
  isExpired: boolean;
  fullySubmitted: boolean;
  questions: QuestionType[];
  surveyName: string;
};

export const useCsatSurvey = ({
  surveyId,
  responderEmail,
  userId,
  firstName,
  lastName,
  hoodName,
}: CsatParams) => {
  const [survey, setSurvey] = useState<{
    isExpired: boolean;
    fullySubmitted: boolean;
    questions: QuestionType[];
    surveyName: string;
  }>({ fullySubmitted: false, isExpired: false, surveyName: '', questions: [] });

  const { loading, error } = useQuery(GET_CSAT_SURVEY, {
    variables: { userMail: responderEmail, surveyId },
    onCompleted: ({ survey }) => {
      if (survey) {
        const {
          isExpired,
          status: { fullySubmitted },
          questions,
          name,
        } = survey;

        setSurvey({
          isExpired,
          fullySubmitted,
          questions: sortQuestions(questions),
          surveyName: name,
        });
      }
    },
  });

  const [submitMutation] = useMutation(SUBMIT_CSAT, {});

  const submitCsat = useCallback(
    (userAnswers: UserAnswers, surveyName: string) => {
      const responses = Object.keys(userAnswers).map((questionId) => ({
        origin: 'EMAIL',
        question: {
          connect: { id: questionId },
        },
        responderEmail,
        responderId: userId,
        value: userAnswers[questionId],
      }));

      submitMutation({
        variables: {
          responses,
          surveyName,
          data: {
            action: 'COMPLETE_SURVEY',
            survey: { connect: { name: surveyName } },
            userId,
          },
        },
      });

      vennAnalytics.track(
        {
          domain: 'CsatSurveyWebPage',
          object: 'CsatSurvey',
          action: 'Submitted',
        },
        {
          email: responderEmail,
          fullName: `${firstName} ${lastName}`,
          hoodName,
          responses: JSON.stringify(
            responses.map((response) => ({
              question: response.question.connect.id,
              value: response.value,
            }))
          ),
        }
      );
    },
    [firstName, hoodName, lastName, responderEmail, submitMutation, userId]
  );

  return {
    error,
    loading,
    survey,
    submitCsat,
  };
};

const sortQuestions = (questions: QuestionType[]) => {
  return questions.sort((a, b) => {
    if (a.order > b.order) {
      return 1;
    }
    if (a.order < b.order) {
      return -1;
    }
    return 0;
  });
};
