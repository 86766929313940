import styled, { css } from 'styled-components';
import media from '../../style/media';

export const header1Bold = css`
  font-style: normal;
  font-weight: bold;
  font-family: 'MarkPro-Bold';
  font-size: 48px;
  line-height: 50px;
  ${media.mobile`
    font-size: 32px;
    line-height: 42px;
  `};
  ${media.mobileSmall`
    font-size: 24px;
    line-height: 26px;
  `};
`;

export const H1Bold = styled.h1`
  ${header1Bold}
`;

export const header2Bold = css`
  font-style: normal;
  font-weight: bold;
  font-family: 'MarkPro-Bold';
  font-size: 40px;
  line-height: 48px;
  ${media.mobile`
    font-size: 28px;
    line-height: 32px;
  `};
  ${media.mobileSmall`
    font-size: 20px;
    line-height: 26px;
  `};
`;

export const H2Bold = styled.h2`
  ${header2Bold}
`;

export const header3 = css`
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 42px;
  ${media.mobile`
    font-size: 24px;
    line-height: 28px;
  `};
`;

export const header3Medium = css`
  ${header3};
  font-weight: bold;
`;

export const header3Bold = css`
  ${header3};
  font-weight: bold;
  font-family: 'MarkPro-Bold';
  ${media.mobileSmall`
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
  `};
`;

export const H3 = styled.h3`
  ${header3}
`;

export const H3Medium = styled.h3`
  ${header3Medium}
`;

export const H3Bold = styled.h3`
  ${header3Bold};
`;

export const paragraph3 = css`
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 32px;
  ${media.mobile`
    font-size: 24px;
    line-height: 28px;
  `};
  ${media.mobileSmall`
    font-size: 16px;
    line-height: 18px;
  `};
`;

export const paragraph3Medium = css`
  ${paragraph3};
  font-weight: bold;
  ${media.mobile`
    font-size: 18px;
    line-height: 24px;
  `};
`;

export const paragraph3Bold = css`
  ${paragraph3Medium};
  font-family: 'MarkPro-Bold';
  ${media.mobileSmall`
    line-height: 22px;
  `};
`;

export const P3 = styled.p`
  ${paragraph3};
`;

export const P3Medium = styled.p`
  ${header3Medium}
`;

export const P3Bold = styled.p`
  ${header3Bold};
`;
